import * as React from "react";
import { graphql, PageProps } from "gatsby";
import { PortableText } from "@portabletext/react";
import styled from "styled-components";
import { Caption1, tokens } from "@fluentui/react-components";
import { formatDateTime } from "../lib/dateTimeUtil";

const PostWrap = styled.div`
  padding: 30px;
`;

const HeaderWrap = styled.div`
  padding-top: 20px;
`;

const Title = styled.div`
  font-size: 25px;
  font-width: 800;
`;

const UpdatedAt = styled(Caption1)`
  color: ${tokens.colorNeutralForeground3};
  padding-left: 5px;
`;

const post: React.FC<PageProps<Queries.PostPageQuery>> = (props) => {
  const postData = props.data?.allSanityPost?.nodes?.[0];

  return (
    <PostWrap>
      <HeaderWrap>
        <Title>{postData?.title}</Title>
        <UpdatedAt>{formatDateTime(postData?._updatedAt)}</UpdatedAt>
      </HeaderWrap>
      {postData?._rawBody ? (
        <PortableText value={postData?._rawBody as { [key: string]: any }} />
      ) : (
        <span>Error</span>
      )}
    </PostWrap>
  );
};

export const query = graphql`
  query PostPage($id: String) {
    allSanityPost(filter: { id: { eq: $id } }) {
      nodes {
        title
        _rawBody
        _updatedAt
      }
    }
  }
`;

export default post;
